<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewT">
				<div class="tabItem" :class="tab == 0 ? 'active' : ''" @click="handelTabClick(0)">商品</div>
				<div class="tabItem" :class="tab == 1 ? 'active' : ''" @click="handelTabClick(1)">套餐</div>
				<div class="tabItem" :class="tab == 2 ? 'active' : ''" @click="handelTabClick(2)">服务</div>
				<div class="tabItem" :class="tab == 3 ? 'active' : ''" @click="handelTabClick(3)">虚拟卡</div>
				<div class="tabItem" :class="tab == 4 ? 'active' : ''" @click="handelTabClick(4)">优惠劵</div>
				<div class="tabItem" :class="tab == 5 ? 'active' : ''" @click="handelTabClick(5)">宠物</div>
			</div>
			<div class="tabViewB">
				<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
					style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
				<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
				<div class="search" v-if="tab !== 5">
					<div class="searchTitle">适用宠物:</div>
					<el-select v-model="applyType" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in petTypeOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search" v-if="tab == 0">
					<div class="searchTitle">商品排序:</div>
					<el-select v-model="stock" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in stockOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search" v-if="tab == 4">
					<div class="searchTitle">虚拟券状态:</div>
					<el-select v-model="status" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tabViewBR">
					<el-button @click="handleResetClick">重置</el-button>
					<el-button @click="exportClick">导出</el-button>
					<el-button  @click="dialogGoodExport_state = true" v-if="tab==0&&$buttonAuthority('cangku-chanpinList-batch')">批量新增商品</el-button>
					<el-button type="primary" v-if="$buttonAuthority('cangku-chanpinList-add')" @click="handleDetailClick(1)">新建{{
					addBtnName }}</el-button>
				</div>
			</div>
		</div>
		<!-- 内容信息 -->
		<div class="view">
			<!-- 左侧树 -->
			<div class="viewL">
				<div class="viewLT">
					<el-tree :data="treeData ? treeData.itemList : null" :props="treeProps" @node-click="handleNodeClick"
						:render-content="renderContent" :highlight-current='isHighlight' node-key="id" default-expand-all
						:expand-on-click-node="false" ref="tree"></el-tree>
				</div>
				<div class="viewLB" v-if="tab==0||tab==1">
					<div @click="handleAddTypeClick(1)">
						<i class="el-icon-plus"></i>
						<span>新增</span>
					</div>
					<div class="line"></div>
					<div @click="handleResetClick">
						<i class="el-icon-refresh-left"></i>
						<span>重置</span>
					</div>
				</div>
			</div>
			<!-- 右侧列表 -->
			<div class="viewR">
				<div class="tableView">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="barCode" label="条码" key="27" v-if="tab != 4 && tab != 5">
						</el-table-column>
						<el-table-column prop="cover" label="照片" key="25" v-if="tab == 5">
							<template slot-scope="scope">
								<div class="tableImg" v-if="scope.row.cover">
									<img :src="scope.row.cover" alt="" />
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="nickName" label="名称" key="26" v-if="tab == 5">
						</el-table-column>
						<el-table-column prop="title" label="名称" key="1" v-if="tab != 4 && tab != 5">
							<template slot-scope="scope">
								<div v-if="scope.row.cover == '' || !scope.row.cover">{{ scope.row.title }}</div>
								<el-tooltip v-else placement="bottom" effect="light">
									<div slot="content">
										<img :src="scope.row.cover" style="width:100px;" />
									</div>
									<div>{{ scope.row.title }}</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="simpleCode" label="简码" width="80" key="2" v-if="tab != 4 && tab != 5">
							<template slot-scope="scope">
								<span v-if="scope.row.simpleCode == ''">--</span>
								<span v-else>{{ scope.row.simpleCode }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="applyType.name" label="类型" width="60" key="3" v-if="tab != 4 && tab != 5">
						</el-table-column>
						<el-table-column label="分类" v-if="tab != 4">
							<template slot-scope="scope">
								<span v-if="scope.row.firstCategoryInfo && scope.row.secondCategoryInfo">{{
					scope.row.firstCategoryInfo.name }}/{{ scope.row.secondCategoryInfo.name }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="petSex" label="性别" key="4" v-if="tab == 5">
							<template slot-scope="scope">
								<span>{{ scope.row.petSex == 1 ? '公' : '母' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="bronDate" label="年龄" key="5" v-if="tab == 5" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.bronDate">{{ $public.calculatePetAge($public.FTime(scope.row.bronDate))[0] }}岁{{
					$public.calculatePetAge($public.FTime(scope.row.bronDate))[1]
				}}月</span>
							</template>
						</el-table-column>
						<el-table-column prop="epromNo" label="芯片号" key="8" v-if="tab == 5">
							<template slot-scope="scope">
								<span>{{ scope.row.epromNo }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="状态" key="6" v-if="tab == 5">
							<template slot-scope="scope">
								<span>{{ scope.row.status ? scope.row.status.name : '未知' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="inShopDays" label="到店日期" key="7" v-if="tab == 5" width="230">
							<template slot-scope="scope">
								<span>{{ $public.FTime(scope.row.inShopDays) }}</span>
							</template>
						</el-table-column>

						<el-table-column prop="model" label="规格" key="9" v-if="tab == 0">
						</el-table-column>
						<el-table-column prop="currentCostPrice" label="成本价" width="80" key="10" v-if="tab == 0">
						</el-table-column>
						<el-table-column prop="salePrice" label="售价" width="80" key="11" v-if="tab == 0 || tab == 5">
						</el-table-column>
						<el-table-column prop="currentCount" label="库存" width="80" key="12" v-if="tab == 0">
						</el-table-column>
						<el-table-column prop="salePrice" label="套餐价" key="13" v-if="tab == 1">
						</el-table-column>
						<el-table-column prop="salePrice" label="售价" key="14" v-if="tab == 2">
						</el-table-column>
						<el-table-column prop="remark" label="备注" key="15" v-if="tab == 2">
						</el-table-column>
						<el-table-column prop="currentStock" label="次数" key="16" v-if="tab == 3">
							<template slot-scope="scope">
								<span v-if="scope.row.timesInfo">{{ scope.row.timesInfo.limitTimes ? scope.row.timesInfo.totalTimes :
					'不限' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="currentStock" label="期限/天" key="17" v-if="tab == 3">
							<template slot-scope="scope">
								<span v-if="scope.row.daysLimit">{{ scope.row.daysLimit.daysLimit ? scope.row.daysLimit.days : '不限'
									}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="salePrice" label="售价" key="18" v-if="tab == 3">
						</el-table-column>

						<!-- 券 -->
						<el-table-column prop="title" label="名称" key="19" v-if="tab == 4">
						</el-table-column>
						<el-table-column label="规格" key="20" v-if="tab == 4">
							<template slot-scope="scope">
								<template v-if="scope.row.ruleInfo">
									<span v-if="scope.row.ruleInfo.couponType.id == '1'">满{{ scope.row.ruleInfo.full }}减{{
					scope.row.ruleInfo.deduct }}</span>
									<span v-if="scope.row.ruleInfo.couponType.id == '2'">{{ scope.row.ruleInfo.immediateDeduct }}元</span>
								</template>

							</template>
						</el-table-column>
						<el-table-column prop="ruleInfo.couponType.name" label="类型" key="21" v-if="tab == 4">
						</el-table-column>
						<el-table-column prop="description" label="说明" key="22" v-if="tab == 4">
						</el-table-column>
						<el-table-column prop="currentStock" label="时间" key="23" v-if="tab == 4" width="200">
							<template slot-scope="scope">
								<span v-if="scope.row.availableTime">{{ $public.FTime(scope.row.availableTime.start, 'YYYY-MM-DD') }}至{{
					$public.FTime(scope.row.availableTime.end, 'YYYY-MM-DD') }}</span>
							</template>
						</el-table-column>
						<el-table-column label="状态" key="24" v-if="tab == 4" width="60">
							<template slot-scope="scope">
								<span v-if="scope.row.onlineStatus">{{ scope.row.onlineStatus.id == '0' ? '下架' : '上架' }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<template v-if="tab == 4 && scope.row.onlineStatus">
									<el-button type="text" size="small"
										v-if="scope.row.onlineStatus.id == '0'&&$buttonAuthority('cangku-chanpinList-unmount')" @click="handleStatusClick(scope.row, '1')">上架</el-button>
									<el-button type="text" size="small" 
										v-if="scope.row.onlineStatus.id == '1'&&$buttonAuthority('cangku-chanpinList-unmount')" @click="handleStatusClick(scope.row, '0')">下架</el-button>
								</template>
								<el-button type="text" size="small" v-if="tab == 4&&$buttonAuthority('cangku-chanpinList-trackList')" 
									@click="handleCouponRecordClick(scope.row)">领取记录</el-button>
								<el-button type="text" size="small" @click="handleMingXiClick(scope.row)" v-if="tab == 0&&$buttonAuthority('cangku-chanpinList-track')"
									>明细</el-button>
								<el-button v-if="$buttonAuthority('cangku-chanpinList-edit')"  type="text" size="small"
									@click="handleEditClick(scope.row)">编辑</el-button>
								<el-button v-if="$buttonAuthority('cangku-chanpinList-del')"  type="text" size="small"
									@click="handleDeleteClick(scope.row)">删除</el-button>
									<el-button type="text" size="small" v-if="tab == 1 || tab == 2 || tab == 3 || tab == 0"
									@click="printCustom(scope.row)">打印条码</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
		<div v-if="dialogGoodExport_state">
			<dialogGoodExport @submit="submit_dialogGoodExport"></dialogGoodExport>
		</div>
		<div v-if="dialogGExport_state">
			<dialogGExport :tableObj="tableObj" @submit="submit_dialogGExport"></dialogGExport>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import printJs from "../../../util/print.js";
import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
import page from "../../../components/page.vue"
import dialogGoodExport from "../../../components/dialogGoodExport.vue"
import dialogGExport from "../../../components/dialogGExport.vue"
export default {
	components: {
		dialogTypeSetting,
		page,
		dialogGoodExport, dialogGExport 
	},
	data() {
		return {
			page: {   //分页
				pageIndex: 1,
				pageSize: 10,
				total: 0,
			},
			pageSizes: [10, 15,30,60],
			tab: 0, //tab切换
			keyword: '', //关键字
			treeData: {}, //树形列表
			treeProps: {
				children: 'itemList',
				label: 'name'
			},
			dialogTypeSetting_state: false, //商品类型设置
			addType: 1, //新增类目   1为1级类目 2为2级类目  3为编辑类目
			applyType: '', //宠物类型 
			petTypeOptions: [{ label: '全部', value: '' },{ label: '通用', value: 'common' }, { label: '猫猫', value: 'cat' }, { label: '狗狗', value: 'dog' }, { label: '其他', value: 'yichong' }],
			stock: 0,
			stockOptions: [{ label: '最新添加', value: 0 }, { label: '售价低到高', value: 1 }, { label: '售价高到低', value: 2 }, { label: '库存低到高', value: 3 }, { label: '库存高到低', value: 4 }],
			status: '',     //虚拟券状态
			statusOptions: [{ label: '全部', value: '' }, { label: '下架', value: '0' }, { label: '上架', value: '1' }],  //
			tableData: [],
			isHighlight: true,
			nodeData: {},  //点击树形数据
			imgUrl1: '',
			categoryId: '',  //类型id
			code:'',
			addBtnName: '商品',  //新建按钮文案
			dialogGExport_state: false,
			dialogGoodExport_state: false
		}
	},
	mounted() {
		this.getTreeData()
		this.getTableData()
	},
	//路由守卫
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// 根据缓存来加载数据
			if (sessionStorage.getItem('mark') == 1) {
				vm.page.pageIndex = 1;
				vm.getTableData();
				vm.getTreeData();
				sessionStorage.removeItem('mark')
			}
		})
	},
	methods: {
		submit_dialogGExport() {
			this.dialogGExport_state = false;
		},
		// 导入
		submit_dialogGoodExport(obj) {
			if (obj.type === 'success') {
				console.log(obj,'obj');
				this.tableObj=obj.data
				this.dialogGExport_state = true;
			}
			this.dialogGoodExport_state = false;
		},
		printCustom(row) {
			console.log(row,'row');
			let params = {
				code: row.barCode,
				name: row.title,
				sku: row.model,
				price: row.salePrice
			};
			console.log(params, "params");
			printJs.printTicketBarCode(params).then((res) => {
				console.log(res, 'res');
				this.$store.commit("set_printUrl", res);
				var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
         hostObject.print(res);

			});

		},
		// 导出
		exportClick() {
			if (this.tab == 0) {
				this.exportUrl('/product/goods/list/export')
			} else if (this.tab == 1) {
				this.exportUrl('/product/goods/group/list/export')
			} else if (this.tab == 2) {
				this.exportUrl('/product/life/service/list/export')
			} else if (this.tab == 3) {
				this.exportUrl('/product/virtual/card/list/export')
			} else if (this.tab == 4) {
				this.exportUrl('/product/virtual/coupons/list/export')
			}
		},
		//导出切换地址
		exportUrl(url) {
			axios({
				method: 'get',
				url: process.env.VUE_APP_export + url,
				responseType: 'blob',
				params: {
					keyword: this.keyword,
					categoryId: this.categoryId,
					applyType: this.applyType
				}
			})
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data], {
						type: "application/ms-excel"
					}));
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = url;
					if (this.tab == 0) {
						link.setAttribute('download', `商品导出.xls`);
					} else if (this.tab == 1) {
						link.setAttribute('download', `套餐导出.xls`);
					} else if (this.tab == 2) {
						link.setAttribute('download', `服务导出.xls`);
					} else if (this.tab == 3) {
						link.setAttribute('download', `虚拟卡导出.xls`);
					} else if (this.tab == 4) {
						link.setAttribute('download', `虚拟券导出.xls`);
					}
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				})
		},
		//筛选
		handleSearchClick() {
			this.page.pageIndex = 1;
			this.getTableData()
		},
		//跳转明细
		handleMingXiClick(row) {
			this.$router.push({ path: 'mingxi', query: { editMsg: JSON.stringify(row) } })
		},
		//领取记录
		handleCouponRecordClick(row) {
			this.$router.push({ path: 'couponRecord', query: { msg: JSON.stringify(row) } })
		},
		//是否上下架
		handleStatusClick(row, status) {
			let params = {
				id: row.virtualId,
				status: status
			}
			this.$http.post("/product/virtual/coupons/update/online/status", params).then(res => {
				if (res.code === 0) {
					if (status == '0') {
						this.$message({
							type: 'success',
							message: '已下架'
						})
						this.getTableData()
					} else if (status == '1') {
						this.$message({
							type: 'success',
							message: '已上架'
						})
						this.getTableData()
					}
				}
			})
		},
		//重置
		handleResetClick() {
			this.keyword = '';
			this.applyType = '';
			this.page.pageIndex = 1;
			this.categoryId = '';
			this.code='';
			this.$refs.tree.setCurrentKey(null)
			if (this.tab == 0) {
				this.stock = 0;
			}
			this.getTableData();
		},
		//编辑商品
		handleEditClick(row) {
			if (this.tab == 0) {   //编辑商品
				let params = {
					id: row.goodsId
				}
				this.$http.get("/product/goods/editmodel/info", params).then(res => {
					if (res.code === 0) {
						this.$router.push({ path: 'goodsDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			} else if (this.tab == 1) {   //编辑套餐
				let params = {
					id: row.groupId
				}
				this.$http.get("/product/goods/group", params).then(res => {
					if (res.code === 0) {
						let itemList = res.data.groupItems;
						let arr = [];
						itemList.map((item) => {
							arr.push({
								newData: {
									"productId": item.goodsId,
									"productName": item.title,
									"productType": 1,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
									"brand": item.brand,
									"firstCategoryId": item.firstCategoryInfo ? item.firstCategoryInfo.id : '',
									"firstCategoryName": item.firstCategoryInfo ? item.firstCategoryInfo.name : '',
									"secondCategoryId": item.firstCategoryInfo ? item.secondCategoryInfo.id : '',
									"secondCategoryName": item.firstCategoryInfo ? item.secondCategoryInfo.name : '',
									"modelValue": item.model,
									"unit": "",
									"originalPrice": item.salePrice,
									"price": item.salePrice,
									"num": item.count,
									"activityId": "",
									"activityName": "",
									"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
									"payType": "",  // 
									"remark": "",
									"isGive": false,  // 是否赠送
									"buyBack": item.buyBack,  // 是否复购
									"salesList": [],
									"productDetails": []
								}
							})
						})
						res.data.groupItems = arr;
						this.$router.push({ path: 'comboDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			} else if (this.tab == 2) {   //编辑服务
				let params = {
					id: row.serviceId
				}
				this.$http.get("/product/life/service", params).then(res => {
					if (res.code === 0) {
						this.$router.push({ path: 'serviceDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			} else if (this.tab == 3) {   //编辑卡

				let params = {
					id: row.virtualId
				}
				this.$http.get("/product/virtual/card", params).then(res => {
					if (res.code === 0) {
						let item = res.data.cardServiceObject;
						let arr = [{
							newData: {
								"productId": item.lifeServiceId,
								"productName": item.lifeServiceName,
								"productType": 2,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
								"brand": '',
								"firstCategoryId": item.firstCategoryInfo ? item.firstCategoryInfo.id : '',
								"firstCategoryName": item.firstCategoryInfo ? item.firstCategoryInfo.name : '',
								"secondCategoryId": item.firstCategoryInfo ? item.secondCategoryInfo.id : '',
								"secondCategoryName": item.firstCategoryInfo ? item.secondCategoryInfo.name : '',
								"modelValue": '',
								"unit": "",
								"originalPrice": item.salePrice,
								"price": item.salePrice,
								"num": 1,
								"activityId": "",
								"activityName": "",
								"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
								"payType": "",  // 
								"remark": "",
								"isGive": false,  // 是否赠送
								"buyBack": item.buyBack,  // 是否复购
								tableKey: item.lifeServiceId + Math.random(),
								"petId": "",
								"petName": "",
								"staffIds": [],
								"salesList": [],
								"productDetails": [],
								"weightDetail": item.weightDetail
							}
						}];
						res.data.cardServiceObject = arr;
						this.$router.push({ path: 'virtualDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			} else if (this.tab == 4) {   //编辑券
				let params = {
					id: row.virtualId
				}
				this.$http.get("/product/virtual/coupons", params).then(res => {
					if (res.code === 0) {
						this.$router.push({ path: 'couponDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			} else if (this.tab == 5) {   //编辑宠物
				let params = {
					id: row.petId
				}
				this.$http.get("/product/pet/editmodel/info", params).then(res => {
					if (res.code === 0) {
						this.$router.push({ path: 'huotiDetail', query: { editMsg: JSON.stringify(res.data) } })
					}
				})
			}
		},
		//删除商品
		handleDeleteClick(row) {
			this.$confirm('是否删除该产品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (this.tab == 0) {   //删除商品
					let params = {
						id: row.goodsId
					}
					this.$http.delete("/product/goods", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})
				} else if (this.tab == 1) {  //删除套餐
					let params = {
						id: row.groupId
					}
					this.$http.delete("/product/goods/group", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})

				} else if (this.tab == 2) {  //删除服务
					let params = {
						id: row.serviceId
					}
					this.$http.delete("/product/life/service", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})
				} else if (this.tab == 3) {  //删除卡
					let params = {
						id: row.virtualId
					}
					this.$http.delete("/product/virtual/card", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})
				} else if (this.tab == 4) {  //删除券
					let params = {
						id: row.virtualId
					}
					this.$http.delete("/product/virtual/coupons", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})

				} else if (this.tab == 5) {  //删huoti 
					let params = {
						id: row.petId
					}
					this.$http.delete("/product/pet", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已删除'
							});
							this.getTableData()
						}
					})

				}

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
		//切换分页
		changePage(num) {
			this.page.pageIndex = num
			this.getTableData();
		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		//获取table列表
		getTableData() {
			let params = {
				pageIndex: this.page.pageIndex,
				pageSize: this.page.pageSize,
				keyword: this.keyword,
				categoryId: this.code,
				applyType: this.applyType
			}
			if (this.tab == 0) {   //商品列表
				params.sortIndex = this.stock;
				this.$http.post("/product/goods/stock/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			} else if (this.tab == 1) {   //套餐列表
				this.$http.post("/product/goods/group/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			} else if (this.tab == 2) {   //服务列表
				this.$http.post("/product/life/service/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			} else if (this.tab == 3) {   //虚拟卡列表
				this.$http.post("/product/virtual/card/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			} else if (this.tab == 4) {   //优惠券列表
				params.onlineStatus = this.status;
				this.$http.post("/product/virtual/coupons/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			} else if (this.tab == 5) {   //宠物列表
				params.status = '';
				this.$http.post("/product/pet/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			}
		},
		//新建
		handleDetailClick() {
			if (this.tab == 0) {
				this.$router.push({ path: 'goodsDetail', query: { editMsg: null } })
			} else if (this.tab == 1) {
				this.$router.push({ path: 'comboDetail', query: { editMsg: null } })
			} else if (this.tab == 2) {
				this.$router.push({ path: 'serviceDetail', query: { editMsg: null } })
			} else if (this.tab == 3) {
				this.$router.push({ path: 'virtualDetail', query: { editMsg: null } })
			} else if (this.tab == 4) {
				this.$router.push({ path: 'couponDetail', query: { editMsg: null } })
			} else if (this.tab == 5) {
				this.$router.push({ path: 'huotiDetail', query: { editMsg: null } })
			}
		},
		//点击树节点删除
		removeProductClick(node, data) {
			this.$confirm('是否删除该类目?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					id: data.id,
					companyId: data.companyInfo.id
				}
				this.$http.get("/cms/System/DeleteSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getTreeData()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		//点击树节点编辑
		editProductClick(data) {
			this.addType = 3;
			this.nodeData = data;
			this.dialogTypeSetting_state = true;
		},
		//点击树节点新增
		addProductClick(data, type) {
			this.addType = type;
			this.nodeData = data;
			this.dialogTypeSetting_state = true;
		},
		//点击树节点
		handleNodeClick(data) {
			console.log(data,'data');
			this.code=data.code;
			this.categoryId = data.id;
			this.page.pageIndex = 1;
			this.getTableData()
		},
		//新增类目
		handleAddTypeClick(type) {
			this.addType = type;
			this.nodeData = this.treeData;
			this.dialogTypeSetting_state = true;
		},
		//关闭类型设置
		submit_dialogTypeSetting() {
			this.getTreeData()
			this.dialogTypeSetting_state = false;
		},
		//获取树
		getTreeData() {
			let params = {}
			if (this.tab == 0) {
				params = {
					code: 'goodstype'
				}
			} else if (this.tab == 1) {
				params = {
					code: 'combotype'
				}
			} else if (this.tab == 2) {
				params = {
					code: 'servicetype'
				}
			} else if (this.tab == 3) {
				params = {
					code: 'virtualtype'
				}
			} else if (this.tab == 4) {
				params = {
					code: 'coupontype'
				}
			} else if (this.tab == 5) {
				params = {
					code: 'pettype'
				}
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.treeData = res.data;
				}
			})
		},
		//tab切换
		handelTabClick(e) {
			this.tab = e;
			if (e == 0) {
				this.addBtnName = '商品'
			} else if (e == 1) {
				this.addBtnName = '套餐'
			} else if (e == 2) {
				this.addBtnName = '服务'
			} else if (e == 3) {
				this.addBtnName = '虚拟卡'
			} else if (e == 4) {
				this.addBtnName = '优惠券'
			} else if (e == 5) {
				this.addBtnName = '宠物'
			}
			this.keyword = '';
			this.applyType = '';
			this.page.pageIndex = 1;
			this.categoryId = '';
			if (this.tab == 0) {
				this.stock = 0;
			}
			this.getTreeData()
			this.getTableData()
		},
		// 树节点按钮
		renderContent(h, { node, data }) {
			let btn = null
			if (data.level == 3) {
				btn = (
					<span class="custom-tree-node">
						<span>{node.label}</span>
						<span class='custom-tree-btn'>
							<el-button size="mini" type="text" icon='el-icon-plus' on-click={() => this.addProductClick(data, 2)}></el-button>
							<el-button size="mini" type="text" icon='el-icon-edit' on-click={() => this.editProductClick(data)}></el-button>
							<el-button size="mini" type="text" icon='el-icon-delete' on-click={() => this.removeProductClick(node, data)}></el-button>
						</span>
					</span>
				)
			} else if (data.level == 4) {
				btn = (
					<span class="custom-tree-node">
						<span>{node.label}</span>
						<span class='custom-tree-btn'>
							<el-button size="mini" type="text" icon='el-icon-edit' on-click={() => this.editProductClick(data)}></el-button>
							<el-button size="mini" type="text" icon='el-icon-delete' on-click={() => this.removeProductClick(node, data)}></el-button>
						</span>
					</span>
				)
			}
			return btn
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;
	display: flex;
	flex-direction: column;

	// 搜索信息
	.tabView {
		height: 161px;
		border-radius: 20px;
		background: #fff;
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		.tabViewT {
			height: 80px;
			border-bottom: solid 1px #F2F2F2;
			display: flex;
			align-items: center;

			.tabItem {
				width: 116px;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
				border-radius: 40px;
				margin-left: 20px;
				cursor: pointer;
				background: #FFF0E0;
			}

			.active {
				background: #F77E04;
				color: #fff;
			}
		}

		.tabViewB {
			flex: 1;
			display: flex;
			align-items: center;
			padding: 0 20px;

			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;

				.searchTitle {
					font-size: 14px;
					color: $fontColor;
					margin-right: 4px;
				}
			}

			.tabViewBR {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}

	// 内容信息
	.view {
		// flex: 1;
		height: calc(100% - 177px);
		display: flex;

		.viewL {
			width: 200px;
			margin-right: 16px;
			background: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			padding-top: 20px;

			.viewLT {
				flex: 1;
				padding: 0 10px;
				overflow-y: scroll;
			}

			.viewLT::-webkit-scrollbar {
				display: none;
			}

			.viewLB {
				margin-top: 20px;
				height: 36px;
				display: flex;
				box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.09);
				border-radius: 0px 0px 20px 20px;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				cursor: pointer;

				.line {
					width: 1px;
					background: #F2F2F2;
				}

				div:nth-of-type(1) {
					flex: 1;
					color: #F77E04;
				}

				div:nth-of-type(3) {
					flex: 1;
					color: #919191;
				}

				i {
					margin-right: 5px;
				}
			}
		}

		.viewR {
			flex: 1;
			background: #fff;
			border-radius: 20px;
			overflow: hidden;
			display: flex;
			flex-direction: column;

			.tableView {
				flex: 1;
				overflow-y: scroll;
			}

			.tableView::-webkit-scrollbar {
				display: none;
			}

			.tableImg {
				width: 80px;
				height: 80px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

}
</style>
