<template>
	<div class="HSMask">
		<div class="HSDialog" style="width: 1000px;height:900px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">导入文件</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<div class="HSDialogBtn">
				<el-button :type="tab == 0 ? 'primary' : ''" @click="handleTabClick(0)">正常</el-button>
				<el-button :type="tab == 1 ? 'primary' : ''" @click="handleTabClick(1)">异常</el-button>
			</div>
      <div v-if="tab == 1" >
				<el-table :data="tableObj.fail" height="200"
					style="width: 100%;box-sizing: border-box;height:580px;overflow: auto;">
          <el-table-column label="异常信息">
						<template slot-scope="scope">
							{{ scope.row.error }}
						</template>
					</el-table-column>
					<el-table-column label="商品">
						<template slot-scope="scope">
							{{ scope.row.data.goodsName }}
						</template>
					</el-table-column>
					<el-table-column width="125px" label="一级分类">
						<template slot-scope="scope">
							{{ scope.row.data.firstCategory }}
						</template>
					</el-table-column>
					<el-table-column label="二级分类" width="120px">
						<template slot-scope="scope">
							{{ scope.row.data.secondCategory }}
						</template>
					</el-table-column>
					<el-table-column label="品牌">
						<template slot-scope="scope">
							{{ scope.row.data.brand }}
						</template>
					</el-table-column>
					<el-table-column label="供应商" >
						<template slot-scope="scope">
							{{ scope.row.data.supply }}
						</template>
					</el-table-column>
					<el-table-column label="宠物种类">
						<template slot-scope="scope">
							{{ scope.row.data.petType }}
						</template>
					</el-table-column>
					<el-table-column label="条码" width="150px">
						<template slot-scope="scope">
							{{ scope.row.data.barCode }}
						</template>
					</el-table-column>
					<el-table-column label="规格">
						<template slot-scope="scope">
							{{ scope.row.data.model }}
						</template>
					</el-table-column>
					<el-table-column label="库存">
						<template slot-scope="scope">
							{{ scope.row.data.stock }}
						</template>
					</el-table-column>
					<el-table-column label="成本价">
						<template slot-scope="scope">
							{{ scope.row.data.costPrice }}
						</template>
					</el-table-column>
					<el-table-column label="售价">
						<template slot-scope="scope">
							{{ scope.row.data.salePrice }}
						</template>
					</el-table-column>
					<el-table-column label="质保天数">
						<template slot-scope="scope">
							{{ scope.row.data.qaDays }}
						</template>
					</el-table-column>
					<el-table-column label="启用无库存销售">
						<template slot-scope="scope">
							{{ scope.row.data.enableNoStockSale }}
						</template>
					</el-table-column>
					<el-table-column label="最小库存">
						<template slot-scope="scope">
							{{ scope.row.data.minStock }}
						</template>
					</el-table-column>
					<el-table-column label="复购提醒">
						<template slot-scope="scope">
							{{ scope.row.data.buyBack }}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div v-if="tab == 0" >
				<el-table height="200" :data="tableObj.success" style="width: 100%;box-sizing: border-box;height:580px;overflow: auto;">
					<el-table-column label="商品">
						<template slot-scope="scope">
							{{ scope.row.goodsName }}
						</template>
					</el-table-column>
					<el-table-column width="125px" label="一级分类">
						<template slot-scope="scope">
							{{ scope.row.firstCategory }}
						</template>
					</el-table-column>
					<el-table-column label="二级分类" width="120px">
						<template slot-scope="scope">
							{{ scope.row.secondCategory }}
						</template>
					</el-table-column>
					<el-table-column label="品牌">
						<template slot-scope="scope">
							{{ scope.row.brand }}
						</template>
					</el-table-column>
					<el-table-column label="供应商" width="180px">
						<template slot-scope="scope">
							{{ scope.row.supply }}
						</template>
					</el-table-column>
					<el-table-column label="宠物种类">
						<template slot-scope="scope">
							{{ scope.row.petType }}
						</template>
					</el-table-column>
					<el-table-column label="条码">
						<template slot-scope="scope">
							{{ scope.row.barCode }}
						</template>
					</el-table-column>
					<el-table-column label="规格">
						<template slot-scope="scope">
							{{ scope.row.model }}
						</template>
					</el-table-column>
					<el-table-column label="库存">
						<template slot-scope="scope">
							{{ scope.row.stock }}
						</template>
					</el-table-column>
					<el-table-column label="成本价">
						<template slot-scope="scope">
							{{ scope.row.costPrice }}
						</template>
					</el-table-column>
					<el-table-column label="售价">
						<template slot-scope="scope">
							{{ scope.row.salePrice }}
						</template>
					</el-table-column>
					<el-table-column label="质保天数">
						<template slot-scope="scope">
							{{ scope.row.qaDays }}
						</template>
					</el-table-column>
					<el-table-column label="启用无库存销售">
						<template slot-scope="scope">
							{{ scope.row.enableNoStockSale }}
						</template>
					</el-table-column>
					<el-table-column label="最小库存">
						<template slot-scope="scope">
							{{ scope.row.minStock }}
						</template>
					</el-table-column>
					<el-table-column label="复购提醒">
						<template slot-scope="scope">
							{{ scope.row.buyBack }}
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="del(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		
			<div class="HSDialogText">
				<div>
					温馨提示：
				</div>
				<div> 品牌只能是数字
					日期只能是 日期格式
					请确保二级分类是唯一的
					只有添加了宠物的昵称 宠物才能被添加
					请删除空白行数据</div>
				<div>异常数据不会被提交</div>
			</div>
			<div class="HSDialoDown" v-html="info">
			</div>
			

			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tab: 1,
			info: ''
		}
	},
	props: {
		tableObj: {
			default: () => {
				return {}
			}
		}
	},
	computed: {

	},
	mounted() {
		console.log(this.tableObj, 'tableObjtableObj');
	},
	methods: {
		// 删除
		del(index) {
			let list = [...this.tableObj.success];
			list.splice(index, 1);
			this.tableObj.success = list;
		},
		//  切换
		handleTabClick(i) {
			this.tab = i
		},
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
			this.info = ''
		},
		// 提交
		submit() {
			this.$http.post("/product/goods/import", this.tableObj.success).then(res => {
				if (res.code === 0) {
					console.log(res, 'res');
					this.info = res.data
					// this.$emit("submit", { type: "success", data: res.data })
				} else {
					// this.fileList=[]
					this.info = res.data
					console.log(res.info);
				}
			})
			// this.$emit("submit", { type: 'success', data: "" })
		},

	}
};
</script>
<style lang="scss" scoped>
.HSDialogCom {
	// flex-wrap: wrap;
}

.HSDialogBtn {
	display: flex;
	margin-left: 30px;
}

.HSDialogText {
	margin-left: 20px;
	font-size: 14px;
}

.HSDialoDown {
	width: 100%;
	padding: 20px 30px;
	box-sizing: border-box;
	height: 100px;
	overflow: auto;
	font-size: 13px;
	color: red;
}

.HSDText {
	color: red;
}
</style>
